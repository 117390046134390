import React from "react"

import Layout from "../templates/Layout";

import * as styles from "./contact.module.css"

class Contact extends React.Component {
    render() {

        return (
            <Layout
                title="Contact"
                location={this.props.location}
                className={styles.wrapper}
            >
                <iframe style={{width: "100%", minHeight: "1400px"}} title="contact form" src="https://docs.google.com/forms/d/e/1FAIpQLSehn82CqWO2RTzhQ6he94kVCn2OWDsD25JYhqRkTJFrrLr3Sw/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </Layout>
        )
    }
}
    
export default Contact